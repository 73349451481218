import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class ModalComponent extends Vue {
  @Prop({ required: false }) openModal!: boolean
  @Prop({ required: false }) label!: string
  @Prop({ required: false }) iconLeft!: string
  @Prop({ required: false }) btnClass!: string
  @Prop({ required: false }) size!: number
  modalOpen = false
  img = 'TestDash.svg'

  closeModal() {
    this.$emit('closeModal')
  }
  mounted() {
    this.modalOpen = this.openModal
  }
  @Watch('openModal')
  onModal(value: string, oldValue: string) {
    this.modalOpen = this.openModal
  }
}
